import type { api } from '@meterup/proto';
import {
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  isOnline,
  PositiveBadge,
} from '@meterup/common';
import { List, ListItem, ListItemHeader, ListTitle, Small2 } from '@meterup/metric';
import { ListContent } from '@meterup/metric/src/components/List/ListContent';
import { uniqBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchClientConnectionHistory, fetchDevice } from '../../../../../api/api';
import { Box } from '../../../../../components/Box';
import {
  ClientHardwareWidget,
  WiredConnectionWidget,
  WirelessConnectionWidget,
  WirelessQualityWidget,
} from '../../../../../components/clients';
import { ListItemTableContainer } from '../../../../../components/ListItemTableContainer';
import { NoValue } from '../../../../../components/NoValue';
import { Page } from '../../../../../components/Page/Page';
import { AutoTable2 } from '../../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { NotFoundError } from '../../../../../errors';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { colors } from '../../../../../stitches';
import {
  AccessPointHeadingCell,
  getAPNameForClient,
} from '../../../../../utils/access_point_utils';
import { isLastSeenKnown, isWired, isWireless } from '../../../../../utils/clientLists';
import formatTime from '../../../../../utils/formatTime';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

const rankBy = <T, U>(items: T[], iteratee: (item: T) => U): { value: T; rank: number }[] => {
  let rank = 0;
  let lastValue: U | undefined;
  return items.map((item) => {
    if (iteratee(item) === lastValue) {
      return { value: item, rank };
    }
    lastValue = iteratee(item);
    rank += 1;
    return { value: item, rank };
  });
};

const bldr = createColumnBuilder<api.UserClient>();

const columns = [
  bldr.data((d) => getAPNameForClient(d), {
    header: 'Access point',
    meta: {
      isLeading: true,
    },
    cell: (p) => <AccessPointHeadingCell name={getAPNameForClient(p.row)} />,
  }),
  bldr.data((d) => d.last_seen ?? '', {
    header: 'Last seen',
    cell: (p) =>
      isLastSeenKnown(p.row) && p.row.last_seen ? (
        formatTime(new Date(p.row.last_seen))
      ) : (
        <NoValue />
      ),
  }),
];

export const Meta = () => ({
  path: '/clients/:macAddress',
});

export default function ClientDetailPage() {
  const controller = useCurrentController();
  const { macAddress } = Nav.useRegionParams('root', paths.pages.ClientDetailPage)!;

  const clientHistory =
    useQuery(
      ['client_history', macAddress],
      () => fetchClientConnectionHistory(controller, macAddress),
      {
        suspense: true,
      },
    ).data ?? [];

  const client = clientHistory[0] ?? null;

  expectDefinedOrThrow(client, new NotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controller, client.apname],
    () => fetchDevice(controller, client.apname),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(client.apname),
    },
  );

  const selectedHistory = Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary);

  const closeDrawer = useCloseDrawerCallback();

  const filteredClientHistory = uniqBy(
    rankBy(clientHistory, (c) => c.apname),
    'rank',
  )
    .map((r) => r.value)
    .slice(0, 10);

  return (
    <Page css={{ gap: 0 }}>
      <Box
        css={{
          width: '100%',
          padding: '$16 $20',
          vStack: '$16',
          alignItems: 'stretch',
          '@sm': { hStack: '$16', alignItems: 'start' },
        }}
      >
        <Box css={{ flex: 1 }}>
          <List>
            <ListItemHeader>
              <ListTitle>Wireless history</ListTitle>
            </ListItemHeader>
            <ListContent>
              {isOnline(client) && (
                <ListItem>
                  <Box
                    css={{
                      width: '100%',
                      vStack: '$4',
                      padding: '$4',
                    }}
                  >
                    <PositiveBadge icon="accessPoint" arrangement="leading-icon" size="large">
                      {getAPNameForClient(client)}
                    </PositiveBadge>
                    <Small2 css={{ color: colors['gray-600'] }}>Current connection</Small2>
                  </Box>
                </ListItem>
              )}
              <ListItemTableContainer>
                <AutoTable2
                  data={filteredClientHistory ?? []}
                  columns={columns}
                  getLinkTo={(row) =>
                    makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
                      deviceName: row.apname,
                    })
                  }
                  isRowSelected={(row) => row.apname === selectedHistory?.deviceName}
                  onRowDeselect={closeDrawer}
                />
              </ListItemTableContainer>
            </ListContent>
          </List>
        </Box>
        <Box
          css={{
            vStack: '$16',
            alignItems: 'stretch',
            minWidth: 320,
          }}
        >
          <ClientHardwareWidget client={client} />
          {isWired(client) && <WiredConnectionWidget client={client} />}
          {isWireless(client) && (
            <>
              <WirelessConnectionWidget client={client} device={device} />
              <WirelessQualityWidget client={client} />
            </>
          )}
        </Box>
      </Box>
    </Page>
  );
}
