import { expectDefinedOrThrow, isDefinedAndNotEmpty } from '@meterup/common';
import {
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  ManufacturerIcon,
  Subheading2,
} from '@meterup/metric';
import { orderBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchClientConnectionHistory, fetchDevice } from '../../../../api/api';
import { ConnectionStatusBadge } from '../../../../components/badges';
import { BandIconAndBadge } from '../../../../components/BandIconAndBadge';
import {
  ClientHardwareWidget,
  WiredConnectionWidget,
  WirelessConnectionWidget,
  WirelessQualityWidget,
} from '../../../../components/clients';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { paths } from '../../../../constants';
import { NotFoundError } from '../../../../errors';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../stitches';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isWired,
  isWireless,
  manufacturerIconName,
} from '../../../../utils/clientLists';

const ClientName = styled('div', {
  vStack: '$8',
  alignItems: 'center',
});

export const Meta = () => ({
  path: '/clients/:macAddress',
});

export default function ClientSummaryPage() {
  const controller = useCurrentController();
  const { macAddress } = Nav.useRegionParams('drawer', paths.drawers.ClientSummaryPage)!;

  const { data: clientHistory } =
    useQuery(
      ['client_history', macAddress],
      () => fetchClientConnectionHistory(controller, macAddress),
      {
        suspense: true,
      },
    ) ?? [];

  const client = orderBy(clientHistory, (d) => d.last_seen, 'desc')[0] ?? null;

  expectDefinedOrThrow(client, new NotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controller, client.apname],
    () => fetchDevice(controller, client.apname),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(client.apname),
    },
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Client</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <ClientName>
          <BandIconAndBadge
            size="medium"
            variant={getClientConnectionStatus(client) === 'online' ? 'positive' : 'neutral'}
            badge={<ConnectionStatusBadge status={getClientConnectionStatus(client)} />}
            icon={<ManufacturerIcon icon={manufacturerIconName(client)} size="medium" />}
          />
          <Subheading2>{clientNameOrNull(client) ?? '-'}</Subheading2>
        </ClientName>
        <ClientHardwareWidget client={client} shouldLinkToDetails />
        {isWired(client) && <WiredConnectionWidget client={client} />}
        {isWireless(client) && (
          <>
            <WirelessConnectionWidget client={client} device={device} />
            <WirelessQualityWidget client={client} />
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
