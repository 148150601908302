import type { api } from '@meterup/proto';
import { isDefined, isDefinedAndNotEmpty, SignalStrengthBadge } from '@meterup/common';
import {
  Badge,
  BodyMono2,
  CopyCapsule,
  MinList,
  MinListItemFooterLink,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
} from '@meterup/metric';
import oui from 'oui';
import React from 'react';

import { paths } from '../constants';
import { getAPNameForDevice } from '../utils/access_point_utils';
import { clientNameOrNull } from '../utils/clientLists';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { pluralize } from '../utils/strings';
import { NoValue } from './NoValue';
import { ReactRouterLink } from './ReactRouterLink';

export const ClientHardwareWidget = ({
  client,
  shouldLinkToDetails,
}: {
  client: api.UserClient;
  shouldLinkToDetails?: boolean;
}) => {
  const manufacturer = oui(client.mac_address);
  return (
    <MinList>
      <MinListItemHeader icon="client">
        <MinListTitle>Client</MinListTitle>
      </MinListItemHeader>
      <MinListItemPair>
        <MinListItemLabel>Manufacturer</MinListItemLabel>
        <MinListItemValue>{manufacturer ?? <NoValue />}</MinListItemValue>
      </MinListItemPair>

      <MinListItemPair>
        <MinListItemLabel>MAC</MinListItemLabel>
        <MinListItemValue>
          <BodyMono2>
            <CopyCapsule
              aria-label="Copy MAC address to clipboard"
              arrangement="leading-icon"
              textValue={client.mac_address}
            >
              {client.mac_address}
            </CopyCapsule>
          </BodyMono2>
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Hostname</MinListItemLabel>
        <MinListItemValue>
          {clientNameOrNull(client) ? (
            <CopyCapsule
              aria-label="Copy hostname to clipboard"
              arrangement="leading-icon"
              textValue={client.name}
            >
              {client.name}
            </CopyCapsule>
          ) : (
            <NoValue />
          )}
        </MinListItemValue>
      </MinListItemPair>
      {shouldLinkToDetails && (
        <MinListItemFooterLink
          as={ReactRouterLink}
          to={makeLink(paths.pages.ClientDetailPage, { macAddress: client.mac_address })}
          // TODO: Figure out why replace={false} is needed here. This should be the default value.
          replace={false}
        >
          View client
        </MinListItemFooterLink>
      )}
    </MinList>
  );
};
export const WiredConnectionWidget = ({ client }: { client: api.UserClient }) => (
  <MinList>
    <MinListItemHeader icon="wired">
      <MinListTitle>Connection</MinListTitle>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemLabel>IP</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2>
          <CopyCapsule
            aria-label="Copy IP address to clipboard"
            arrangement="leading-icon"
            textValue={client.ip_address}
          >
            {client.ip_address}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
  </MinList>
);

export const WirelessConnectionWidget = ({
  device,
  client,
}: {
  device: api.MeterDevice | null | undefined;
  client: api.UserClient;
}) => (
  <MinList>
    <MinListItemHeader icon="wifi">
      <MinListTitle>Connection</MinListTitle>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemLabel>Access point</MinListItemLabel>
      <MinListItemValue>
        {isDefined(device) ? (
          <Badge
            icon="accessPoint"
            arrangement="leading-icon"
            variant={device.status === 'online' ? 'positive' : 'neutral'}
            size="small"
            ends="pill"
          >
            {getAPNameForDevice(device)}
          </Badge>
        ) : (
          <NoValue />
        )}
      </MinListItemValue>
    </MinListItemPair>
    {isDefinedAndNotEmpty(client.ssids) && (
      <MinListItemPair>
        <MinListItemLabel>{pluralize(client.ssids.length, 'SSID', 'SSIDs')}</MinListItemLabel>
        <MinListItemValue>{client.ssids.join(', ')}</MinListItemValue>
      </MinListItemPair>
    )}
    <MinListItemPair>
      <MinListItemLabel>Channel</MinListItemLabel>
      <MinListItemValue>
        {client.channel !== 0 ? (
          <Badge arrangement="leading-icon" variant="neutral" size="small">
            {client.channel}
          </Badge>
        ) : (
          <NoValue />
        )}
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>IP</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2>
          <CopyCapsule
            aria-label="Copy IP address to clipboard"
            arrangement="leading-icon"
            textValue={client.ip_address}
          >
            {client.ip_address}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
  </MinList>
);

export const WirelessQualityWidget = ({ client }: { client: api.UserClient }) => (
  <MinList>
    <MinListItemHeader icon="noise">
      <MinListTitle>Quality</MinListTitle>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemLabel>Signal</MinListItemLabel>
      <MinListItemValue>
        {client.signal !== 0 ? <SignalStrengthBadge value={client.signal} /> : <NoValue />}
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Noise</MinListItemLabel>
      <MinListItemValue>
        {client.noise !== 0 ? (
          <Badge arrangement="leading-icon" variant="neutral" size="small" ends="pill">
            {client.noise} dBm
          </Badge>
        ) : (
          <NoValue />
        )}
      </MinListItemValue>
    </MinListItemPair>
  </MinList>
);
