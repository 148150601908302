import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';
import { Outlet } from 'react-router';

import { DrawerRoutes } from '../../../components/DrawerRoutes';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';

export const Meta: PagefileMetaFn = () => ({
  path: '/',
  name: 'NetworkLayout',
  layout: 'SidebarLayout',
});

export default function NetworkLayout() {
  return <MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />;
}
