import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';
import { useFocusVisible } from 'react-aria';
import { Outlet } from 'react-router';

import { CurrentControllerProvider } from '../../providers/CurrentControllerProvider';
import { SearchParamsStateProvider } from '../../providers/SearchParamsStateProvider';
import { focusVisibleClassName } from '../../stitches';

export const Meta: PagefileMetaFn = () => ({
  name: 'ProvidersLayout',
});

export default function ProvidersLayout() {
  const { isFocusVisible } = useFocusVisible();

  return (
    <SearchParamsStateProvider>
      <CurrentControllerProvider>
        <div
          className={isFocusVisible ? focusVisibleClassName : ''}
          style={{ display: 'contents' }}
        >
          <Outlet />
        </div>
      </CurrentControllerProvider>
    </SearchParamsStateProvider>
  );
}
