import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  DetailPageLayouts,
  DeviceStatusIcon,
  expectDefinedOrThrow,
} from '@meterup/common';
import { Heading2, Tab, Tabs } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as ReactRouterLink } from 'react-router-dom';

import { fetchDevice } from '../../../../../api/api';
import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { paths } from '../../../../../constants';
import { NotFoundError } from '../../../../../errors';
import { useIsActiveMatcher } from '../../../../../hooks/useIsActive';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { getAPNameForDevice } from '../../../../../utils/access_point_utils';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

const AccessPointDetailPageHeader = () => {
  const controller = useCurrentController();
  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.AccessPointDetailPage),
  );
  const isActiveTest = useIsActiveMatcher();

  const device =
    useQuery(['device', controller, deviceName], () => fetchDevice(controller, deviceName), {
      suspense: true,
    }).data ?? null;

  expectDefinedOrThrow(device, new NotFoundError('Client not found'));

  return (
    <DetailPageLayouts.Container>
      <DetailPageLayouts.HeaderContent>
        <ReactRouterLink to={makeLink(paths.pages.AccessPointDetailPage, { deviceName })}>
          <DeviceStatusIcon value={device.status} />
        </ReactRouterLink>
        <DetailPageLayouts.HeadingAndTabs>
          <Heading2>{getAPNameForDevice(device)}</Heading2>
          <Tabs>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.AccessPointDetailPage, { deviceName })}
              active={isActiveTest(paths.pages.AccessPointDetailPage, { exact: true })}
              icon="client"
              annotation={device.clients}
            >
              Clients
            </Tab>
          </Tabs>
        </DetailPageLayouts.HeadingAndTabs>
      </DetailPageLayouts.HeaderContent>
    </DetailPageLayouts.Container>
  );
};

export const Meta: PagefileMetaFn = () => ({
  path: '/devices/:deviceName',
  layout: 'SidebarLayout',
});

export default function AccessPointDetailLayout() {
  return <DetailPageLayout header={<AccessPointDetailPageHeader />} />;
}
