import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/pages/App.layout.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/pages/Providers.layout.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Network.layout.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Sidebar.layout.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/ISPList.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/InternetAndWireless.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/SSIDList.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPointDetail.layout.tsx";
import PagefilesImport8 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPointDetail.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPointList.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/clients/ClientDetail.layout.tsx";
import PagefilesImport11 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/clients/ClientDetail.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/clients/Clients.page.tsx";

const routes = [
{element: React.createElement(PagefilesImport1)},
{children: [{children: [{path: "/clients/:macAddress",children: [{index: true,element: React.createElement(PagefilesImport11)},],element: React.createElement(PagefilesImport10)},
{path: "/devices/:deviceName",children: [{index: true,element: React.createElement(PagefilesImport8)},],element: React.createElement(PagefilesImport7)},
{path: "/",children: [{path: "/clients",element: React.createElement(PagefilesImport12)},
{path: "/devices",element: React.createElement(PagefilesImport9)},
{path: "/internet-wifi",element: React.createElement(PagefilesImport5)},
{path: "/isps",element: React.createElement(PagefilesImport4)},
{path: "/ssids",element: React.createElement(PagefilesImport6)},],element: React.createElement(PagefilesImport2)},],element: React.createElement(PagefilesImport3)},],element: React.createElement(PagefilesImport0)},
];

export default routes;