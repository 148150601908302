import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { AccessPointSummaryDrawer } from '../../../../components/AccessPoints/AccessPointSummary';

export const Meta: PagefileMetaFn = () => ({
  path: '/devices/:deviceName',
});

export default function AccessPointSummary() {
  return <AccessPointSummaryDrawer />;
}
