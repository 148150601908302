import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AccessPoints from '../../../../../components/AccessPoints/AccessPoints';

export const Meta: PagefileMetaFn = () => ({
  path: '/devices',
  layout: 'NetworkLayout',
});

export default function AccessPointListPage() {
  return <AccessPoints />;
}
