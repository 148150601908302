import type { api } from '@meterup/proto';
import type { SortingState } from '@tanstack/react-table';
import { Badge, BodyMono2, Button, ManufacturerIcon, SmallMono2, TextInput } from '@meterup/metric';
import React from 'react';

import { paths } from '../constants';
import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { Nav } from '../nav';
import { useSearchParamsState } from '../providers/SearchParamsStateProvider';
import { routes } from '../routes';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isOnline,
  isWired,
  isWireless,
  manufacturerIconName,
} from '../utils/clientLists';
import { makeDrawerLink } from '../utils/main_and_drawer_navigation';
import { Box } from './Box';
import { PageControls, PageHeader, PageSection } from './Page/Page';
import { ReactRouterLink } from './ReactRouterLink';
import { AutoTable2 } from './Table/AutoTable2';
import { createColumnBuilder } from './Table/createColumnBuilder';
import { createFilterSet } from './Table/createFilterSet';

const builder = createColumnBuilder<api.UserClient>();

const columns = [
  builder.data((d) => `${getClientConnectionStatus(d)} ${isWireless(d) ? 'wireless' : 'wired'}`, {
    header: '',
    minSize: 40,
    maxSize: 40,
    meta: {
      sizingMode: 'fit-min',
      alignment: 'end',
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isOnline(p.row) ? 'positive' : 'neutral'}
        icon={isWireless(p.row) ? 'wifi' : 'wired'}
        size="small"
        ends="pill"
      >
        {isOnline(p.row) ? 'Online' : 'Offline'}
      </Badge>
    ),
  }),
  builder.data((row) => row.name, {
    header: 'Name',
    minSize: 120,
    size: 300,
    meta: {
      isLeading: true,
      sizingMode: 'truncate',
    },
    cell: (p) => (
      <Box css={{ hStack: '$8' }}>
        <ManufacturerIcon icon={manufacturerIconName(p.row)} size="small" />
        <Box css={{ truncate: true }}>{clientNameOrNull(p.row) ?? <BodyMono2>-</BodyMono2>}</Box>
      </Box>
    ),
  }),
  builder.data((row) => row.ip_address, {
    header: 'IP',
    meta: { sizingMode: 'fit-max', isCopyable: true },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((row) => row.mac_address, {
    header: 'MAC',
    meta: {
      sizingMode: 'fit-max',
      isCopyable: true,
    },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((d) => d.signal.toFixed(0), {
    header: 'Signal (dBm)',
    meta: {
      sizingMode: 'fit-min',
    },
    cell: (p) =>
      isWireless(p.row) ? (
        <Badge
          arrangement="leading-icon"
          variant={p.row.signal > -74 ? 'positive' : 'negative'}
          size="small"
          ends="pill"
        >
          {p.value}
        </Badge>
      ) : (
        <SmallMono2>-</SmallMono2>
      ),
  }),
];

const Filters = createFilterSet<api.UserClient>(
  [
    {
      key: 'all',
      label: 'All',
      predicate: () => true,
    },
    {
      key: 'wireless',
      label: 'Wireless',
      predicate: isWireless,
    },
    {
      key: 'wired',
      label: 'Wired',
      predicate: isWired,
    },
  ],
  {
    urlKey: 'tab',
  },
);

export const ClientsList = ({ clients }: { clients: api.UserClient[] }) => {
  const params = Nav.useRegionParams('drawer', routes.drawers.clients.detail.path);

  const closeDrawer = useCloseDrawerCallback();
  const filteredClients = Filters.useFilteredData(clients);

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <>
      <PageHeader>
        <Filters.TabSwitcher data={clients} />
        <PageControls>
          <div style={{ width: 'fit-content' }}>
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, routes.drawers.clients.add.path, {})}
              variant="tertiary"
              icon="plusCircle"
              arrangement="leading-icon"
            >
              Add client
            </Button>
          </div>
          <div style={{ minWidth: 56 }}>
            <TextInput
              aria-label="Filter clients"
              icon="searchScoped"
              value={globalFilter}
              onChange={setGlobalFilter}
            />
          </div>
        </PageControls>
      </PageHeader>
      <PageSection>
        <AutoTable2
          columns={columns}
          data={filteredClients}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          globalFilter={globalFilter}
          getLinkTo={(row) =>
            makeDrawerLink(window.location, paths.drawers.ClientSummaryPage, {
              macAddress: row.mac_address,
            })
          }
          isRowSelected={(row) => params?.macAddress === row.mac_address}
          onRowDeselect={closeDrawer}
        />
      </PageSection>
    </>
  );
};
