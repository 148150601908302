import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  DesktopSidebar,
  MobileSidebar,
  MobileSidebarLocationControlContainer,
  MobileSidebarToggle,
  MobileSidebarToggleContainer,
  SidebarGroup,
  SidebarNavItem,
} from '@meterup/metric';
import React, { useState } from 'react';
import { Outlet } from 'react-router';

import { LocationControl } from '../../../components/Navigation/LocationControl';
import { MobileSidebarModal } from '../../../components/Navigation/MobileSidebarModal';
import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { SidebarAndMainLayout } from '../../../components/SidebarAndMainLayout';
import { paths } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useLogoutHandler } from '../../../hooks/useLogoutHandler';
import { Nav } from '../../../nav';
import { useCurrentControllerVersion } from '../../../providers/CurrentControllerProvider';
import { styled } from '../../../stitches';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  name: 'SidebarLayout',
  layout: 'AppLayout',
});

const LogoutButton = styled('div', { marginTop: 'auto', width: '100%', padding: '$12' });

export const SidebarEntries = ({ onNavItemClick }: { onNavItemClick?: () => void }) => {
  const location = Nav.useRegionLocation('root');
  const isActiveTest = useIsActiveMatcher();
  const controllerVersion = useCurrentControllerVersion();
  const flags = useFeatureFlags();

  return (
    <>
      <SidebarGroup>
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink('/', {})}
          label="Overview"
          isSelected={location.pathname === '/'}
          icon="home"
          onClick={onNavItemClick}
        />
      </SidebarGroup>
      <SidebarGroup>
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink(paths.pages.ClientsPage, {})}
          label="Clients"
          isSelected={isActiveTest('/clients', { exact: false })}
          icon="client"
          onClick={onNavItemClick}
        />
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink(paths.pages.AccessPointListPage, {})}
          label="Access points"
          isSelected={isActiveTest(paths.pages.AccessPointListPage, { exact: false })}
          icon="device"
          onClick={onNavItemClick}
        />
      </SidebarGroup>
      <SidebarGroup>
        {controllerVersion.isCOS && flags['cos-config'] ? (
          <>
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.ISPListPage, {})}
              label="ISPs"
              isSelected={isActiveTest(paths.pages.ISPListPage)}
              icon="internet"
              onClick={onNavItemClick}
            />
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.SSIDListPage, {})}
              label="SSIDs"
              isSelected={isActiveTest(paths.pages.SSIDListPage)}
              icon="wifi"
              onClick={onNavItemClick}
            />
          </>
        ) : (
          <SidebarNavItem
            as={ReactRouterLink}
            to={makeLink(paths.pages.InternetAndWirelessPage, {})}
            label="ISPs"
            isSelected={isActiveTest(paths.pages.InternetAndWirelessPage)}
            icon="internet"
            onClick={onNavItemClick}
          />
        )}
      </SidebarGroup>
    </>
  );
};
const NetworkSidebarDesktop = () => (
  <DesktopSidebar>
    <SidebarEntries />
    <LogoutButton>
      <SidebarNavItem as="button" label="Log out" onClick={useLogoutHandler()} />
    </LogoutButton>
  </DesktopSidebar>
);

const NetworkSidebarMobile = ({ onClose }: { onClose: () => void }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <MobileSidebarToggle icon="cross" label="Navigation" onClick={onClose} />
      </MobileSidebarToggleContainer>
      <SidebarEntries onNavItemClick={onClose} />
      <SidebarGroup>
        <SidebarNavItem as="button" label="Log out" onClick={useLogoutHandler()} />
      </SidebarGroup>
      <MobileSidebarLocationControlContainer>
        <LocationControl onClose={onClose} />
      </MobileSidebarLocationControlContainer>
    </MobileSidebar>
  </MobileSidebarModal>
);

export default function SidebarLayout() {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const openMobileMenu = () => setIsMobileSidebarOpen(true);
  const closeMobileMenu = () => setIsMobileSidebarOpen(false);

  return (
    <SidebarAndMainLayout
      mobileSidebarToggle={
        <MobileSidebarToggle icon="menu" label="Meter" onClick={openMobileMenu} />
      }
      mobileSidebar={
        isMobileSidebarOpen ? <NetworkSidebarMobile onClose={closeMobileMenu} /> : null
      }
      desktopSidebar={<NetworkSidebarDesktop />}
      main={<Outlet />}
    />
  );
}
