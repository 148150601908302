import type { SortingState } from '@tanstack/react-table';
import { isDefined, PanAndZoomRegion, preloadImage, shadows } from '@meterup/common';
import { Badge, Button, TextInput } from '@meterup/metric';
import { styled } from '@meterup/metric/src/stitches.config';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';

import type { DeviceDataAndRadios } from '../../api/types';
import { fetchDevicesWithRadioData, fetchFloorPlan } from '../../api/api';
import { paths } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { Nav } from '../../nav';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { routes } from '../../routes';
import { colors } from '../../stitches';
import {
  AccessPointHeadingCell,
  formatAPRadiosForBand,
  getAPNameForDevice,
  isOffline,
  isOnline,
  RadioNumbersOrDisabled,
} from '../../utils/access_point_utils';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { Box } from '../Box';
import { Page, PageControls, PageHeader, PageSection } from '../Page/Page';
import { ReactRouterLink } from '../ReactRouterLink';
import { AutoTable2 } from '../Table/AutoTable2';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { createFilterSet } from '../Table/createFilterSet';

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  boxShadow: shadows.fenceAllLight,
  padding: 0.5,
});

const ViewMode = createFilterSet<DeviceDataAndRadios>(
  [
    {
      key: 'list',
      label: 'List',
      icon: 'menu',
      predicate: () => true,
    },
    {
      key: 'floor-plan',
      label: 'Floor plan',
      icon: 'floorplan',
      predicate: () => true,
    },
  ],
  {
    urlKey: 'view',
  },
);

const DeviceFilters = createFilterSet<DeviceDataAndRadios>(
  [
    {
      key: 'all',
      label: 'All',
      predicate: () => true,
    },
    {
      key: 'online',
      label: 'Online',
      predicate: isOnline,
    },
    {
      key: 'offline',
      label: 'Offline',
      predicate: isOffline,
    },
  ],
  {
    urlKey: 'tab',
  },
);

const builder = createColumnBuilder<DeviceDataAndRadios>();

const columns = [
  builder.data((d) => d.device.status, {
    header: '',
    minSize: 40,
    maxSize: 40,
    meta: {
      sizingMode: 'fit-min',
      alignment: 'end',
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isOnline(p.row) ? 'positive' : 'neutral'}
        icon={isOnline(p.row) ? 'checkmarkCircle' : 'crossCircle'}
        size="small"
        ends="pill"
      >
        {isOnline(p.row) ? 'Online' : 'Offline'}
      </Badge>
    ),
  }),
  builder.data((d) => getAPNameForDevice(d.device), {
    header: 'Name',
    meta: {
      isLeading: true,
    },
    cell: (p) => <AccessPointHeadingCell name={getAPNameForDevice(p.row.device)} />,
  }),
  builder.data((d) => d.device.clients.toFixed(0), {
    header: 'Clients',
  }),
  builder.data((d) => formatAPRadiosForBand(d.apAndRadios, api.RadioBand.RB_5G) ?? '', {
    header: '5 GHz',
    cell: (p) => <RadioNumbersOrDisabled value={p.row.apAndRadios} band={api.RadioBand.RB_5G} />,
  }),
  builder.data((d) => formatAPRadiosForBand(d.apAndRadios, api.RadioBand.RB_2G) ?? '', {
    header: '2.4 GHz',
    cell: (p) => <RadioNumbersOrDisabled value={p.row.apAndRadios} band={api.RadioBand.RB_2G} />,
  }),
];

const AccessPoints = () => {
  const controller = useCurrentController();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary);

  const devices =
    useQuery(['devices_and_radios', controller], () => fetchDevicesWithRadioData(controller), {
      suspense: true,
    }).data ?? [];

  const floorPlanURL = useQuery(['floor_plan', controller], async () => {
    const url = await fetchFloorPlan(controller);
    return url ? preloadImage(url) : null;
  }).data;

  const filteredDevices = DeviceFilters.useFilteredData(devices);

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const currentView = ViewMode.useCurrentFilterKey();
  return (
    <Page css={{ gap: 0 }}>
      <PageHeader>
        {floorPlanURL && (
          <>
            <ViewMode.TabSwitcher />
            <Box
              css={{
                width: '2px',
                height: '16px',
                background: colors['gray-100'],
                borderRadius: '8px',
              }}
            />
          </>
        )}
        <DeviceFilters.TabSwitcher data={devices} />
        <PageControls>
          <div style={{ width: 'fit-content' }}>
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, routes.drawers.clients.add.path, {})}
              variant="tertiary"
              icon="plusCircle"
              arrangement="leading-icon"
            >
              Add client
            </Button>
          </div>
          <div style={{ minWidth: 56 }}>
            <TextInput
              aria-label="Filter clients"
              icon="searchScoped"
              value={globalFilter}
              onChange={setGlobalFilter}
            />
          </div>
        </PageControls>
      </PageHeader>
      <PageSection>
        {currentView === 'list' ? (
          <AutoTable2
            columns={columns}
            data={filteredDevices}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            globalFilter={globalFilter}
            getLinkTo={(row) =>
              row.device.physical_location !== 'Wired'
                ? makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
                    deviceName: row.device.name,
                  })
                : null
            }
            onRowDeselect={closeDrawer}
            isRowSelected={(d) => d.device.name === drawerParams?.deviceName}
          />
        ) : (
          <PanningContainer>
            <PanAndZoomRegion>
              {isDefined(floorPlanURL) && (
                <img
                  src={floorPlanURL}
                  alt="Floor plan"
                  style={{ maxHeight: '50vh', userSelect: 'none' }}
                />
              )}
            </PanAndZoomRegion>
          </PanningContainer>
        )}
      </PageSection>
    </Page>
  );
};

export default AccessPoints;
