import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/AccessPointSummary.page.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ClientSummary.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/SSIDCreate.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/SSIDEdit.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/SSIDRemove.page.tsx";

const routes = [
{path: "/clients/:macAddress",element: React.createElement(PagefilesImport1)},
{path: "/devices/:deviceName",element: React.createElement(PagefilesImport0)},
{path: "/ssids/:id/edit",element: React.createElement(PagefilesImport3)},
{path: "/ssids/:id/remove",element: React.createElement(PagefilesImport4)},
{path: "/ssids/create",element: React.createElement(PagefilesImport2)},
];

export default routes;