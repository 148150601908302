import React from 'react';
import { useQuery } from 'react-query';

import { fetchClients, fetchControllerClientCountMetrics } from '../../../../../api/api';
import { Box } from '../../../../../components/Box';
import { ClientsList } from '../../../../../components/ClientsList';
import { Page, PageSection } from '../../../../../components/Page/Page';
import { TimeSeriesChart } from '../../../../../components/TimeSeriesChart';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/clients',
  layout: 'NetworkLayout',
});

export default function ClientsPage() {
  const controller = useCurrentController();
  const flags = useFeatureFlags();

  const clients =
    useQuery(['clients', controller], () => fetchClients(controller), { suspense: true }).data ??
    [];

  const clientCount = useQuery(
    ['client_count', controller],
    () => fetchControllerClientCountMetrics(controller),
    {
      suspense: true,
    },
  ).data;

  return (
    <Page css={{ gap: 0 }}>
      <ClientsList clients={clients} />
      {clientCount && flags['metrics-ui'] && (
        <PageSection>
          <Box css={{ padding: '$20' }}>
            <TimeSeriesChart
              title="Client count"
              series={clientCount}
              valueFormatter={(d) => d.toFixed(0)}
            />
          </Box>
        </PageSection>
      )}
    </Page>
  );
}
