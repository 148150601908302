import type { ReactNode } from 'react';
import { DetailPageLayouts } from '@meterup/common';
import { Skeleton } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { styled } from '../stitches';
import { DrawerRoutes } from './DrawerRoutes';
import { ErrorFallback } from './ErrorFallback/ErrorFallback';
import { MainAndDrawerLayout } from './MainAndDrawerLayout';

const Container = styled('div', {
  width: '100%',
  height: '100%',
  display: 'grid',
  overflow: 'auto',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
});

export const DetailPageLayout = ({
  header,
  footer,
  main = <Outlet />,
  drawer = <DrawerRoutes />,
}: {
  header?: ReactNode;
  main?: ReactNode;
  drawer?: ReactNode;
  footer?: ReactNode;
}) => (
  <Container>
    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense
        fallback={
          <DetailPageLayouts.HeaderContainer>
            <Skeleton width="200px" height="28px" radius={8} />
          </DetailPageLayouts.HeaderContainer>
        }
      >
        {header}
      </Suspense>
    </ErrorBoundary>

    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={<Skeleton width="100%" height="240px" radius={8} />}>
        <MainAndDrawerLayout main={main} drawer={drawer} />
      </Suspense>
    </ErrorBoundary>

    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={<Skeleton width="100%" height="28px" radius={8} />}>{footer}</Suspense>
    </ErrorBoundary>
  </Container>
);
