import type { UserTokenResponse } from '@meterup/proto/esm/api';
import type { AxiosResponse } from 'axios';
import { getMany, getOne, isDefined, makeAPICall, milliseconds, mutateVoid } from '@meterup/common';
import { api } from '@meterup/proto';
import axios from 'axios';
import { countBy, orderBy } from 'lodash';
import { bytes, Measure, seconds } from 'safe-units';

import type { MeterV2WirelessTagServiceSet } from './config';
import type {
  ClientData,
  DeviceData,
  DeviceDataAndRadios,
  DevicesData,
  LegacyControllerMessageData,
  LegacyNetworkInfoData,
  ListClientsData,
  LocationData,
  LocationsData,
  MultiTimeSeriesData,
  NetworkPlansData,
  OnboardingData,
  ProvidersData,
  SSIDData,
  StatusData,
  SuggestedPasswordData,
  TimeSeriesData,
  UserData,
  UsersData,
  UsersTokensResponseJSON,
  UserTokenResponseJSON,
} from './types';
import { DURATION_24H_MS } from '../constants';
import { isWireless } from '../utils/clientLists';
import { delay } from '../utils/delay';
import { retry } from '../utils/retry';
import FAKE_NETWORK_INFO_RESPONSE from './data/network_info';
import SIGNAL_STRENGTH_DBM from './data/signal_strength_dbm.json';
import VPP_STATS from './data/vpp_stats.json';

const API_BASE_URL = import.meta.env.REACT_APP_API_URL;

const axiosInstanceJSON = axios.create({
  withCredentials: true,
  baseURL: `${API_BASE_URL}/api-proxy`,
});

export const getIdentity = async () =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<api.IdentityResponse>('/v1/identity');
    return result.data;
  });

export const startNetworkInfoRequest = async (
  networkName: string,
): Promise<LegacyControllerMessageData | null> =>
  getOne(async () => {
    if (import.meta.env.REALM === 'local') {
      return { links: { self: '/' } } as any;
    }

    const response = await axiosInstanceJSON.get<LegacyControllerMessageData>(
      `/v1/controllers/${networkName}/network-info?timeout=20s`,
    );

    return response.data;
  });

const fetchFromLegacyControllerLink = async <T extends any>(link: string): Promise<T> =>
  makeAPICall(async () => {
    const response =
      import.meta.env.REALM === 'local'
        ? await delay(80).then(() => FAKE_NETWORK_INFO_RESPONSE)
        : await axiosInstanceJSON.get<LegacyControllerMessageData>(link, { timeout: 800 });
    return JSON.parse(response.data?.response?.data!);
  });

export const fetchNetworkInfo = async (controllerName: string): Promise<LegacyNetworkInfoData> =>
  makeAPICall(async () => {
    const linkResponse = await startNetworkInfoRequest(controllerName);
    const link = linkResponse?.links?.self;
    if (link) {
      return retry(() => fetchFromLegacyControllerLink<LegacyNetworkInfoData>(link), 7);
    }
    throw new Error('Failed to read network info');
  });

export const fetchNetworkISPInfo = async (company: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<NetworkPlansData>(
      `${API_BASE_URL}/api-proxy/v1/dashboard/controllers/${company}/internet-service-plans`,
    );
    return result.data.plans;
  });

export const fetchControllers = async (company: string): Promise<api.ControllerResponse[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyControllersResponse>(
      `/v1/companies/${company}/controllers`,
    );

    return result.data.controllers;
  });

export const fetchInstalledPrimaryControllers = async (company: string) => {
  const controllers = await fetchControllers(company);
  return controllers.filter(
    (controller) =>
      controller.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY,
  );
};

export const fetchStatus = async (controller: string): Promise<StatusData | null> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<StatusData>(
      `/v1/dashboard/controllers/${controller}/status`,
    );
    return result.data;
  });

export const fetchControllerConfig = async (controller: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<{
      config: object;
      last_updated_at?: string;
    }>(`/v1/controllers/${controller}/config`);
    return result.data;
  });

export const upsertControllerConfigKey = async (
  controllerName: string,
  key: string,
  value: object,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/controllers/${controllerName}/config/${key}`, {
      config: value,
    }),
  );

export const fetchClients = async (controller: string): Promise<api.UserClient[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ClientListDashboardResponse>(
      `/v1/dashboard/controllers/${controller}/clients`,
    );

    return orderBy(result.data.clients, ['last_seen', isWireless], ['desc', 'desc']).filter(
      (client) => !client.ip_address.startsWith('10.102'),
    );
  });

export const fetchDevicesWithRadioData = async (
  controller: string,
): Promise<DeviceDataAndRadios[]> =>
  getMany(async () => {
    const devicesData = await axiosInstanceJSON.get<DevicesData>(
      `/v1/dashboard/controllers/${controller}/devices`,
    );

    const { devices } = devicesData.data;
    const apsAndRadios = devicesData.data.access_points_radios;
    const combined = devices.map((device) => ({
      device: device as DeviceData,
      apAndRadios: apsAndRadios.find((r) => r.access_point?.name === device.name) ?? null,
    }));

    return combined.sort((a, b) => {
      if (a.device.physical_location === 'Wired') {
        return 1;
      }

      if (a.device.clients !== b.device.clients) {
        return a.device.clients > b.device.clients ? -1 : 1;
      }

      return a.device.physical_location.localeCompare(b.device.physical_location);
    });
  });

export const fetchDeviceWithRadioData = async (
  controller: string,
  deviceName: string,
): Promise<DeviceDataAndRadios | null> =>
  getOne(async () => {
    const devices = await fetchDevicesWithRadioData(controller);
    return devices.find((d) => d.device.name === deviceName) ?? null;
  });

export const fetchDevices = async (controller: string): Promise<DeviceData[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<DevicesData>(
      `/v1/dashboard/controllers/${controller}/devices`,
    );

    const { devices } = response.data;
    return orderBy(devices as DeviceData[], (d) => d.clients, 'desc');
  });

export const fetchDevice = async (
  controller: string,
  deviceName: string,
): Promise<DeviceData | null> =>
  getOne(async () => {
    const devices = await fetchDevices(controller);
    return devices.find((device) => device.name === deviceName) ?? null;
  });

export const fetchDeviceClients = async (
  device: string,
  controller: string,
): Promise<ClientData[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<ListClientsData>(
      `/v1/dashboard/controllers/${controller}/clients?ap=${device}`,
    );

    return result.data.clients;
  });

export const fetchUserClients = async (userSid: string) =>
  getMany(async () => {
    const results = await axiosInstanceJSON.get<api.ConnectedClientsResponse>(
      `/v1/dashboard/company-users/${userSid}/clients`,
    );
    return results.data.clients;
  });

export const fetchClientConnectionHistory = async (
  controller: string,
  mac: string,
): Promise<ClientData[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<ListClientsData>(
      `/v1/dashboard/controllers/${controller}/clients?mac=${mac}`,
    );

    return orderBy(result.data.clients, (d) => d.last_seen, 'desc');
  });

export const fetchDeviceConnectionHistory = async (
  controller: string,
  deviceName: string,
): Promise<ClientData[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<ListClientsData>(
      `/v1/dashboard/controllers/${controller}/clients?ap=${deviceName}`,
    );

    return result.data.clients;
  });

export const fetchFloorPlan = async (controller: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get(`/v1/controllers/${controller}/floor-plan`, {
      responseType: 'blob',
    });

    return URL.createObjectURL(result.data);
  });

export const fetchProviders = async (): Promise<api.Provider[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<ProvidersData>('/v1/providers');
    return response.data.providers;
  });

export const getSuggestedPassword = async (wordCount: number) =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<SuggestedPasswordData>('/v1/password-suggestions', {
      params: {
        'word-count': wordCount,
      },
    });
    return result.data.suggested_password;
  });

export const fetchCompanyLocations = async (companySlug: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<LocationsData>(
      `/v1/companies/${companySlug}/locations`,
    );

    return result.data.locations.map((wrapper) => wrapper.location);
  });

export const fetchCompanyLocation = async (
  companySlug: string,
  sublocationSid: string,
): Promise<LocationData | null> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<LocationsData>(
      `/v1/companies/${companySlug}/locations`,
    );

    return (
      result.data.locations
        .map((wrapper) => wrapper.location)
        .find((location) => location.sublocation_sid === sublocationSid) ?? null
    );
  });

export const fetchOnboarding = async (companySlug: string, sublocationSid: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<OnboardingData>('/v1/onboardings', {
      params: {
        'company-slug': companySlug,
        'sublocation-sid': sublocationSid,
      },
    });

    return result.data;
  });

export const updateOnboarding = async (data: Partial<OnboardingData>) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.put<OnboardingData>('/v1/onboardings', data);
    return result.data;
  });

export const createUser = async (
  companySlug: string,
  email: string,
  companyRole: api.CompanyMembershipRole,
) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.post<UserData>(`/v1/companies/${companySlug}/users`, {
      email,
      company_role: companyRole,
    });
    return result.data;
  });

export const getCompanyUsers = async (companySlug: string) =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<UsersData>(`/v1/companies/${companySlug}/users`);

    return response.data.users;
  });

export const getUserTokens = async (companyUserSid: string): Promise<UserTokenResponse[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<UsersTokensResponseJSON>(
      `/v1/company-users/${companyUserSid}/tokens`,
    );

    return response.data.tokens;
  });

export const getToken = async (
  userSid: string,
  tokenSid: string,
): Promise<UserTokenResponseJSON | null> =>
  getOne(async () => {
    const tokens = await getUserTokens(userSid);

    return tokens.find((token) => token.sid === tokenSid) ?? null;
  });

export const createToken = async (
  params: api.UserTokenCreateRequest,
): Promise<api.UserTokenResponse | null> =>
  getOne(async () => {
    const response = await axiosInstanceJSON.post<UserTokenResponseJSON>(
      `/v1/company-users/${params.user_sid}/tokens`,
      params,
    );

    return response.data;
  });

export const getOrCreateSingleToken = async (
  userSid: string,
): Promise<UserTokenResponseJSON | null> =>
  makeAPICall(async () => {
    const tokens = await getUserTokens(userSid);

    if (tokens.length) {
      return tokens[0];
    }

    return createToken({
      alias: 'Default token created in Dashboard',
      max_clients_limit: 10,
      user_sid: userSid,
    });
  });

export const deleteToken = async (userSid: string, tokenSid: string): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/company-users/${userSid}/tokens/${tokenSid}`);
  });

export const getUser = async (companySlug: string, userSid: string): Promise<UserData | null> =>
  getOne(async () => {
    const response = await axiosInstanceJSON.get<UserData>(
      `/v1/companies/${companySlug}/users/${userSid}`,
    );

    return response.data;
  });

export const logoutUser = async (): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post(`/v1/logout`);
  });

export const deleteUser = async (companySlug: string, userSid: string): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/companies/${companySlug}/users/${userSid}`);
  });

export interface TokenAndUser extends UserTokenResponseJSON {
  user: UserData;
}

export const getAllTokenAndUsers = async (companySlug: string): Promise<TokenAndUser[]> => {
  const companyUsers = await getCompanyUsers(companySlug);
  if (isDefined(companyUsers)) {
    return (
      await axios.all(
        companyUsers.map(async (user) => {
          const tokens = await getUserTokens(user.sid);
          return tokens.map((token) => ({
            ...token,
            user,
          }));
        }),
      )
    ).flat();
  }
  return [];
};

const KNOWN_STANDALONE_TOKEN_ALIAS = 'Default standalone token';

export const getOrCreateStandaloneToken = async (
  companySlug: string,
  userSid: string,
): Promise<UserTokenResponseJSON | null> =>
  getOne(async () => {
    const tokenAndUsers = await getAllTokenAndUsers(companySlug);
    const foundToken = tokenAndUsers.find((token) => token.alias === KNOWN_STANDALONE_TOKEN_ALIAS);

    if (isDefined(foundToken)) {
      return foundToken;
    }

    return createToken({
      alias: KNOWN_STANDALONE_TOKEN_ALIAS,
      max_clients_limit: 100,
      user_sid: userSid,
    });
  });

const cosServiceSetToSSIDData = (
  response: LegacyNetworkInfoData,
  ssid: string,
  json: MeterV2WirelessTagServiceSet,
): SSIDData => ({
  ssid,
  type: json.bands?.join(', ') ?? 'Unknown',
  sid: ssid,
  password: json['psk-rotation']
    ? {
        type: 'rotating',
        value: ssid === response.guest_ssid ? response.guest_password ?? '' : '',
        rotation_interval_name: json['psk-rotation']?.frequency,
      }
    : { type: 'static', value: json.psk ?? '' },
});

// This function reshapes the network info data into a list of SSIDs. No
// endpoint exists that would return this data yet.
export async function fetchControllerSSIDs(controller: string): Promise<SSIDData[]> {
  const networkInfoData = await fetchNetworkInfo(controller);

  if (isDefined(networkInfoData)) {
    if (networkInfoData.config_json) {
      return Object.entries(networkInfoData.config_json['service-sets'] ?? {}).map(([ssid, json]) =>
        cosServiceSetToSSIDData(networkInfoData, ssid, json),
      );
    }

    return [
      {
        sid: 'private',
        ssid: networkInfoData.private_ssid,
        password: { type: 'static', value: networkInfoData.private_password } as const,
        type: '5 GHz',
      },
      networkInfoData.private_2g_ssid
        ? {
            sid: 'private_2g',
            ssid: networkInfoData.private_2g_ssid,
            password: { type: 'static', value: networkInfoData.private_password } as const,
            type: '2.4 GHz',
          }
        : null,
      networkInfoData.guest_ssid
        ? {
            sid: 'guest',
            ssid: networkInfoData.guest_ssid,
            password: networkInfoData.guest_password
              ? ({
                  type: 'rotating',
                  value: networkInfoData.guest_password,
                  rotation_interval_name: networkInfoData.guest_strategy,
                } as const)
              : null,
            type: 'Guest',
          }
        : null,
    ].filter(isDefined);
  }

  return [];
}

export async function fetchControllerSSID(
  controller: string,
  sid: string,
): Promise<SSIDData | null> {
  const networkInfoData = await fetchControllerSSIDs(controller);
  return networkInfoData.find((ssid) => ssid.sid === sid) ?? null;
}

interface MetricsDataValue {
  /**
   * Value can be empty string
   */
  ap_name: string;
  /**
   * Value can be empty string
   */
  client_name: string;
  created_at: string;
  value: number;
  json_value: Record<string, number | undefined>;
}

interface MetricsData {
  data: MetricsDataValue[];
}

interface MetricsParams {
  endTime?: Date;
  durationMs?: number;
  downsampleRate?: number;
}

const fetchMetrics = async (
  controllerName: string,
  seriesId: string,
  { endTime = new Date(), durationMs = DURATION_24H_MS, downsampleRate = 1 }: MetricsParams = {},
): Promise<MetricsData> =>
  makeAPICall(async () => {
    const params = {
      series_id: seriesId,
      object_id: controllerName,
      end_time: endTime.toISOString(),
      duration_ms: durationMs,
      downsample_rate: downsampleRate,
    };

    const result = await axiosInstanceJSON.get<{}, AxiosResponse<MetricsData>>('/v1/metrics', {
      params,
    });

    return result.data;
  });

export const fetchControllerClientCountMetrics = async (
  controllerName: string,
  params?: MetricsParams,
): Promise<TimeSeriesData> => {
  const result =
    import.meta.env.REALM === 'local'
      ? SIGNAL_STRENGTH_DBM
      : await fetchMetrics(controllerName, 'signal_strength_dbm', params);

  const timestampCounts = countBy(result?.data ?? [], (data) => data.created_at);

  const values = Object.entries(timestampCounts).map(([timestamp, value]) => ({
    timestamp: new Date(timestamp),
    value,
  }));

  const orderedValues = orderBy(values, 'timestamp', 'asc');

  return {
    data: orderedValues,
  };
};

function computeDataRatesOverTime(result: MetricsData, getter: (d: MetricsDataValue) => number) {
  const data = (result?.data ?? [])
    .map((d) => ({
      timestamp: new Date(d.created_at),
      value: getter(d),
    }))
    .filter((d) => d.value >= 0);

  const sortedData = orderBy(data, (d) => d.timestamp, 'asc');

  return sortedData
    .map((curr, i) => {
      if (i > 0) {
        const prev = sortedData[i - 1];

        const timeDiff = Measure.of(
          curr.timestamp.getTime() - prev.timestamp.getTime(),
          milliseconds,
        ).over(seconds);

        const valueDiff = Measure.of(curr.value - prev.value, bytes);
        const valueBytesPerSecond = valueDiff.over(timeDiff).value;

        return {
          timestamp: curr.timestamp,
          value: valueBytesPerSecond,
        };
      }

      return {
        timestamp: curr.timestamp,
        value: 0,
      };
    })
    .filter((d) => d.value >= 0);
}

export const fetchWANThroughputMetrics = async (
  controllerName: string,
  params?: MetricsParams,
): Promise<MultiTimeSeriesData<'wan0tx' | 'wan0rx' | 'wan1tx' | 'wan1rx'>> => {
  const result =
    import.meta.env.REALM === 'local'
      ? (VPP_STATS as unknown as MetricsData)
      : await fetchMetrics(controllerName, 'meter.v1.netman.vpp.stats', params);

  return {
    seriesList: {
      wan0rx: {
        data: computeDataRatesOverTime(
          result,
          (d: MetricsDataValue) => d.json_value?.['/interfaces/wan0/rx/bytes'] ?? 0,
        ),
      },
      wan0tx: {
        data: computeDataRatesOverTime(
          result,
          (d: MetricsDataValue) => d.json_value?.['/interfaces/wan0/tx/bytes'] ?? 0,
        ),
      },
      wan1rx: {
        data: computeDataRatesOverTime(
          result,
          (d: MetricsDataValue) => d.json_value?.['/interfaces/wan1/rx/bytes'] ?? 0,
        ),
      },
      wan1tx: {
        data: computeDataRatesOverTime(
          result,
          (d: MetricsDataValue) => d.json_value?.['/interfaces/wan1/tx/bytes'] ?? 0,
        ),
      },
    },
  };
};
