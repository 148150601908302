import { Body2, Small2 } from '@meterup/metric';
import { fonts, fontWeights } from '@meterup/metric/src/stitches.config';
import { Axis, buildChartTheme, Grid, LineSeries, Tooltip, XYChart } from '@visx/xychart';
import * as d3 from 'd3';
import React from 'react';

import type { TimeSeriesData, TimeSeriesDataValue } from '../api/types';
import { colors, shadows } from '../stitches';
import { Box } from './Box';

const theme = buildChartTheme({
  svgLabelSmall: {
    fontFamily: fonts.sans.toString(),
    fontWeight: fontWeights.regular.toString(),
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 0,
    fill: colors['gray-500'].toString(),
  },
  xAxisLineStyles: {
    stroke: colors['gray-300'].toString(),
  },
  xTickLineStyles: {
    stroke: colors['gray-300'].toString(),
  },
  yTickLineStyles: {
    stroke: colors['gray-300'].toString(),
  },
  colors: [colors['brand-500'].toString()],
  gridColor: colors['gray-100'].toString(),
  backgroundColor: colors.white.toString(),
  gridColorDark: colors['gray-700'].toString(),
  tickLength: 4,
});

export interface TimeSeriesChartProps {
  title: string;
  series: TimeSeriesData;
  valueFormatter: (value: number) => string;
}

export const TimeSeriesChart = ({ title, series, valueFormatter }: TimeSeriesChartProps) => (
  <Box css={{ boxShadow: shadows.fenceAllLight, borderRadius: '$8' }}>
    <Box css={{ padding: '$8 $20 $4 $20' }}>
      <Body2 css={{ fontWeight: fontWeights.medium }}>{title}</Body2>
    </Box>
    <Box>
      <XYChart
        height={200}
        xScale={{ type: 'time' }}
        yScale={{ type: 'linear' }}
        margin={{ top: 8, left: 100, right: 20, bottom: 24 }}
        theme={theme}
      >
        <Grid columns={false} numTicks={4} />
        <Axis
          orientation="left"
          numTicks={4}
          tickFormat={valueFormatter}
          strokeWidth={1}
          hideAxisLine
          hideZero
        />
        <Axis orientation="bottom" numTicks={8} hideTicks strokeWidth={1} />
        <LineSeries
          dataKey="Rx"
          data={series.data}
          xAccessor={(d) => d.timestamp}
          yAccessor={(d) => d.value}
          curve={d3.curveStepBefore}
          strokeWidth={1.5}
        />
        <Tooltip<TimeSeriesDataValue>
          snapTooltipToDatumX
          showVerticalCrosshair
          showSeriesGlyphs
          unstyled
          applyPositionStyle
          renderTooltip={({ tooltipData }) =>
            tooltipData?.nearestDatum ? (
              <Box
                css={{
                  hStack: '$4',
                  background: colors.white,
                  boxShadow: shadows.overlayLight,
                  padding: '$2 $6',
                  borderRadius: '$6',
                }}
              >
                <Small2 css={{ color: colors['gray-600'] }}>
                  {d3.timeFormat('%I:%M %p')(tooltipData.nearestDatum.datum.timestamp)}
                </Small2>
                <Small2 css={{ fontWeight: fontWeights.medium }}>
                  {valueFormatter(tooltipData.nearestDatum.datum.value)}
                </Small2>
              </Box>
            ) : null
          }
        />
      </XYChart>
    </Box>
  </Box>
);
